// Generated by Framer (2c4b293)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./WvBMv_GA5-0.js";

const cycleOrder = ["oGCdA31Vh"];

const serializationHash = "framer-mCuLa"

const variantClassNames = {oGCdA31Vh: "framer-v-1sdd4cl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {XCjGPTapi: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "oGCdA31Vh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1sdd4cl", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"oGCdA31Vh"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={getLocalizedValue("v0", activeLocale) ?? <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO015cmlhZCBQcm8gQm9sZA==", "--framer-font-family": "\"Myriad Pro Bold\", \"Myriad Pro Bold Placeholder\", sans-serif", "--framer-font-size": "23px", "--framer-letter-spacing": "-0.04em", "--framer-line-height": "1em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>+7 495 150 55 20</motion.p></React.Fragment>} className={"framer-1lh3ouy"} fonts={["CUSTOM;Myriad Pro Bold"]} layoutDependency={layoutDependency} layoutId={"MKg75fL86"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mCuLa.framer-1tjpwuw, .framer-mCuLa .framer-1tjpwuw { display: block; }", ".framer-mCuLa.framer-1sdd4cl { height: 23px; overflow: visible; position: relative; width: 158px; }", ".framer-mCuLa .framer-1lh3ouy { flex: none; height: auto; left: 50%; position: absolute; top: 48%; white-space: pre; width: auto; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 23
 * @framerIntrinsicWidth 158
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWvBMv_GA5: React.ComponentType<Props> = withCSS(Component, css, "framer-mCuLa") as typeof Component;
export default FramerWvBMv_GA5;

FramerWvBMv_GA5.displayName = "Phone";

FramerWvBMv_GA5.defaultProps = {height: 23, width: 158};

addFonts(FramerWvBMv_GA5, [{explicitInter: true, fonts: [{family: "Myriad Pro Bold", source: "custom", url: "https://framerusercontent.com/assets/Io0XzTL1h9R5xVtq4Ycm9ldQw.woff2"}]}], {supportsExplicitInterCodegen: true})